import { useEffect, useState } from 'react';
import ModalCentered from '../../ui/modal-window/ModalCentered';
import styles from '../../../pages/Mailing/MailingForm/mailingForm.module.sass';
import telStyles from './telephones.module.sass';
import { api } from '../../../services/api';
import Btn_transparent from '../../ui/btn_transparent/Btn_transparent';
import TextInput from '../../ui/input/TextInput';
import sharedStyles from '../index.module.sass';
import InfoTooltip from '../../ui/infoTooltip/infoTooltip';
import { fetchTasks } from '../../../store/task-slice/apiActions';
import { useAppDispatch } from '../../../hooks/redux';
import InfoBanner from '../../ui/infoBanner/infoBanner';

const Telephones = () => {
  const dispatch = useAppDispatch();
  const [fileText, setFileText] = useState('');
  const [taskName, setTaskName] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [error, setError] = useState('');
  const [fileLoad, setFileLoad] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const refreshTasks = () => {
    const limit = 10;
    const offset = 0;
    dispatch(fetchTasks({ limit, offset, operation: 'parsing' }));
  };

  useEffect(() => {
    refreshTasks();
  }, []);

  const fileHandlerOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFileText(selectedFile.name);
      setFile(selectedFile);
      uploadFile(selectedFile);
    }
  };

  const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    api
      .post('/telegram/parser/phones/upload_file', formData)
      .then((response) => {
        if (response.status === 200) {
          setFileLoad(true);
        }
      })
      .catch((e) => {
        setError(e);
      });
  };

  const submitHandler = async () => {
    if (!file || !taskName) {
      setError('Пожалуйста, выберите файл и введите название задачи');
      return;
    }

    const fileExtension = file.name.split('.').pop(); // Получаем расширение файла

    const requestData = {
      id_bot: 0,
      task_name: taskName,
      rerun: false,
      export_file_ext: fileExtension,
      phones: [],
      phones_filename: file.name,
    };

    try {
      const response = await api.post('/telegram/parser/phones', requestData);
      if (response.status === 200) {
        setModalShow(true);
        refreshTasks();
      }
    } catch (e: any) {
      setError(e.response.data.detail || 'Ошибка при отправке данных');
    }
  };

  return (
    <section className={telStyles.wrapper}>
      <ModalCentered
        show={modalShow}
        headerText="Парсинг запущен"
        bodyText='Результаты парсинга отобразятся в таблице "История задач"'
        btnText="Закрыть"
        onHide={() => setModalShow(false)}
      />
      <InfoBanner
        text={
          <>
            <a
              href="https://vk.com/@tgparsing-kak-korrektno-otkryt-fail-v-excel-s-dannymi-telegram-akkaunt"
              target="_blank"
              rel="noopener noreferrer"
            >
              👉 Инструкция, как корректно открывать файлы в Excel
            </a>
          </>
        }
        style={{
          width: '100%',
          paddingRight: '54px',
          paddingLeft: '16px',
        }}
        context={'ad'}
      />
      <div className={telStyles.textWrapper}>
        <span className={telStyles.textHeader}>
          Конвертация номеров в аккаунрты телеграм.
        </span>
        <span>Выдача полной информации о номере телефона:</span>
        <ul className={telStyles.textList}>
          <div>
            <li>id</li>
            <li>username</li>
            <li>first_name</li>
            <li>last_name</li>
            <li>premium (true/false)</li>
            <li>bot (true/false)</li>
          </div>
          <div>
            <li>scam (true/false)</li>
            <li>deleted (true/false)</li>
            <li>verified (true/false)</li>
            <li>restricted (true/false)</li>
            <li>fake (true/false)</li>
          </div>
        </ul>
        <span>Конвертация не входит в тариф и оплачивается отдельно.</span>
        <span>Стоимость конвертации:</span>
        <ul className={telStyles.textList}>
          <div>
            <li>база от 100 до 1000 номеров: 5 руб. за строчку</li>
            <li>база от 1000 до 10000 номеров: 4 руб. за строчку</li>
            <li>база от 10000 до 50000 номеров: 3.5 руб. за строчку</li>
            <li>база от 50000 до 100000 номеров: 3 руб. за строчку</li>
            <li>база от 100000 номеров: 2.5 руб. за строчку</li>
          </div>
        </ul>
      </div>
      <div className={styles.uploadedFileInput}>
        <br />
        <input
          type="file"
          id="uploaded-file"
          onChange={fileHandlerOnChange}
          className={telStyles.input}
        />
        <label htmlFor="uploaded-file">
          <p>{fileText ? fileText : 'Выберите файл'}</p>
        </label>
        {fileLoad ? (
          <span className={telStyles.text}>Файл загружен</span>
        ) : null}
      </div>
      <div className={sharedStyles.fieldWrapper}>
        <h3 className={sharedStyles.header}>Название задачи</h3>
        <InfoTooltip text="Дайте название своему парсингу (задаче). Это название будет видно только Вам в таблице с историями по парсингу." />
        <TextInput
          style={{ maxWidth: '610px' }}
          name="name"
          type="text"
          placeholder="Придумайте название задачи"
          hintMessage="Название будет видно только Вам"
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
        />
      </div>
      {error && <p className={telStyles.error}>{error}</p>}
      <Btn_transparent
        style={{ width: '100%' }}
        variant="standart"
        type="button"
        onClick={submitHandler}
      >
        Начать преобразование
      </Btn_transparent>
    </section>
  );
};

export default Telephones;
