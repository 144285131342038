import { CSSProperties, useState } from 'react';
import styles from './infoBanner.module.sass';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close.svg';
import IconButton from '../iconButton/IconButton';

type InfoBannerProps = {
  text: string | React.ReactNode;
  linkText?: string;
  linkURL?: string;
  context: 'ad' | 'tech' | 'adStart';
  style?: CSSProperties;
};

const InfoBanner = ({
  text,
  linkText,
  linkURL,
  context,
  style,
}: InfoBannerProps) => {
  const [opened, setOpened] = useState(true);

  return (
    <>
      {opened && (
        <article
          className={`${styles.infoBanner} ${styles[context]}`}
          style={style}
        >
          <p className={`${styles.infoText} ${styles[context]}`}>
            {text}
            {linkText && linkURL && (
              <span className={styles.infoLink}>
                <a href={linkURL} target="_blank" rel="noopener noreferrer">
                  {linkText}
                </a>
              </span>
            )}
          </p>
          <IconButton
            className={`${styles.infoClose} ${styles[context]}`}
            onClick={() => setOpened(false)}
          >
            <CloseIcon />
          </IconButton>
        </article>
      )}
    </>
  );
};

export default InfoBanner;
